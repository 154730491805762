/* Base styles */

.featured-church {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.featured-church h2 {
  font-family: 'Poppins';
  font-size: 40px;
  font-weight: 700;
  text-align: center;
}
.services {
  padding: 10px;
  text-align: center;
}

.services h2 {
  font-family: 'Poppins';
  font-size: 40px;
  font-weight: 700;
}

.service-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.service-item {
  flex: 1;
  max-width: 400px;
  margin: 20px;
  border: 2px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
  transition: background-color 0.3s ease-in-out;
}

.service-item h2 {
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 10px;
  color: black;
}

.service-item p {
  font-size: 15px;
  margin-bottom: 10px;
  color: gray;
}

.service-item i {
  font-size: 3em;
  margin-bottom: 10px;
  color: red;
}

.service-item:hover {
  background-color: whitesmoke;
  cursor: pointer;
}

/* Testimonials */
.testimonial-item {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 20px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px; /* Rounded corners */
  margin-bottom: 20px; /* Space between testimonials */
  background-color: rgba(240, 240, 240, 0.9);
}
.carousel-container {
  width: 70%;
}
.person {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.circle {
  background-color: #fee7d3;
  border-radius: 50%;
  height: 170px; /* Adjust size as needed */
  width: 170px; /* Adjust size as needed */
  margin-bottom: 20px; /* Space between image and text */
  object-fit: cover; /* Ensures image fills circle */
}

.name {
  color: #404245;
  font-size: 24px;
  font-weight: 600;
  margin-top: 8px; /* Adjust spacing */
}

.title {
  color: #6e6e6e;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-style: italic;
  margin-top: 4px;
}

.testimony {
  flex: 1;
  padding: 0 20px; /* Added padding for spacing */
}

.gallery {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
  background-image: url('../assets/images/bgk2.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  object-fit: cover;
  background-size: cover;
}
.gallery h2 {
  font-family: 'Poppins';
  font-size: 40px;
  font-weight: 700;
  color: whitesmoke;
}
.gallery-grid {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
/* About Us section */
.about-us {
  padding: 50px;
  text-align: center;
}
.about-us h2 {
  font-family: 'Poppins';
  font-size: 40px;
  font-weight: 700;
}
.aboutusmain-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.aboutusmain-container__lefttside {
  width: 100%;
}

.aboutusmain-container__rightside {
  width: 100%;
  margin: 0 20px;
}

.aboutusmain-container__lefttside img {
  height: 500px;
  width: 100%;

  border-radius: 20px;
}

.aboutusmain-container__rightside p {
  font-size: 18px;
  line-height: 1.6;
  color: #333;
}

.aboutusmain-container__rightside h2 {
  font-size: 24px;
  margin-top: 20px;
  color: #333;
}

.about-us {
  padding: 50px;
  text-align: center;
}

.contact-us {
  padding: 50px;
  text-align: center;
}
.contact-us h2 {
  padding: 50px;
  text-align: center;
  font-family: 'Poppins';
  font-size: 40px;
  font-weight: 700;
}
.contact-us__container {
  display: flex;
  justify-content: center;
}
.contact-info {
  margin-bottom: 20px;
  width: 100%;
}

.contact-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact-form input,
.contact-form textarea {
  width: 100%;
  max-width: 500px;
  margin-bottom: 10px;
  padding: 10px;
}
.contact-form button {
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border: none;
  cursor: pointer;
}
.contact-form button:hover {
  background-color: #555;
}

/* Responsive styles */
@media (max-width: 768px) {
  .service-list {
    flex-direction: column;
    margin: 0 40px;
    border-radius: red 2px solid;
  }

  .service-item {
    margin: 10px auto;
  }
  .gallery {
    padding: 20px;
  }

  .about-us,
  .contact-us {
    padding: 20px;
  }
  .about-us h2,
  .contact-us h2,
  .gallery h2,
  .services h2 {
    font-family: 'Poppins';
    font-size: 28px;
    font-weight: 500;
  }
  /* What Our Customers Say */
  .testimonial-item {
    flex-direction: column;
  }
  .carousel-container {
    width: 100%;
  }
  /* about us */
  .aboutusmain-container__lefttside img {
    height: 235px;
    border-radius: 20px;
  }
  /* contact us small screens */
  .aboutusmain-container {
    flex-direction: column;
    justify-content: center;
  }

  .contact-us__container {
    flex-direction: column;
  }
}
