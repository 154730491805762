.contact-us {
  padding: 50px;
  text-align: center;
  background-color: #f8f9fa; /* Light gray background */
}

.contact-us h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #333;
}

.contact-info {
  margin-bottom: 20px;
  font-size: 1.2em;
  color: #555;
}

.contact-form {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff; /* White background for form */
  padding: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border-radius: 8px; /* Rounded corners */
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  margin-bottom: 15px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1em;
  background: #fafafa; /* Slightly darker background for inputs */
  transition: border-color 0.3s;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #333; /* Darker border on focus */
  outline: none;
}

.contact-form textarea {
  height: 150px; /* Fixed height for textarea */
  resize: none; /* Disable resizing */
}

.contact-form button {
  padding: 15px 30px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1.2em;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-form button:hover {
  background-color: #555;
}
