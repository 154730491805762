/* Base styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: rgba(12, 12, 12, 0.9);
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.header .menu-toggle {
  display: none;
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  margin-left: auto;
}

.header nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.header nav ul li {
  font-size: 25px;
  font-family: 'Poppins', sans-serif;
  margin-left: 20px;
}

.header nav ul li a {
  color: #fff;
  text-decoration: none;
}

.header nav ul li a.book-now {
  background-color: #f00;
  padding: 5px 10px;
  border-radius: 5px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .header nav {
    width: 100%;
  }

  .header .menu-toggle {
    background: none;
    border: none;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
    display: block;
    margin-left: auto;
  }

  .header nav ul {
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    top: 60px;
    left: -100%; /* Hide off-screen by default */
    background-color: #333;
    width: 80%; /* Sidebar width */
    max-width: 300px; /* Max width for larger screens */
    height: 100vh;
    padding: 20px;
    transition: left 0.3s ease;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  }

  .header nav ul.open {
    left: 0; /* Slide in from left when menu is open */
  }

  .header nav ul li {
    margin-left: 0;
    margin-bottom: 10px;
  }

  .header nav ul li a {
    font-size: 18px; /* Increase font size for smaller screens */
  }

  .header nav ul li a.book-now {
    font-size: 18px; /* Adjust font size for Book Now button */
  }
}
