/* Gallery Styles */
.gallery {
  padding: 20px;
  text-align: center;
  background-color: rgb(250, 235, 215, 0.8);
}

.gallery h2 {
  font-size: 32px;
  margin-bottom: 20px;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.gallery-card {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.image-container {
  position: relative;
}

.image-container img {
  width: 100%;
  height: auto;
  display: block;
}

.overlayGallery {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: height 0.3s ease;
}

.overlayGallery-text {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}

.image-container:hover .overlayGallery {
  height: 100%;
}

.card-details {
  display: flex;
  padding: 10px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-details h3 {
  font-size: 18px;
  margin: 10px 0 5px;
}

.card-details p {
  margin: 5px 0;
  font-size: 16px;
}

.order-button {
  background-color: #f00;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.order-button:hover {
  background-color: #c00;
}

/* Modal Styles */
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.modal-overlayGallery {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.modal h2 {
  margin-bottom: 20px;
  font-size: 24px;
}

.order-form {
  display: flex;
  flex-direction: column;
}

.order-form label {
  margin-bottom: 10px;
}

.order-form input {
  width: calc(100% - 20px);
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.modal button {
  display: block;
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal button:hover {
  background-color: #0056b3;
}
