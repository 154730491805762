.footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.footer .quick-links {
  margin-bottom: 20px;
}

.footer .quick-links a {
  color: #fff;
  margin: 0 10px;
  text-decoration: none;
}

.footer .quick-links a:hover {
  text-decoration: underline;
}

.footer .contact-info {
  margin-bottom: 20px;
}

.footer .social-media {
  margin-top: 20px;
}

.footer .social-media a {
  color: #fff;
  margin: 0 10px;
  text-decoration: none;
  font-size: 1.5em;
}

.footer .social-media a:hover {
  color: #ddd;
}
