.featured-church {
  padding: 2rem;
}

.featured-cards {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.featured-card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  width: calc(33.333% - 1rem);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.featured-card:hover {
  transform: translateY(-5px);
}

.featured-card__image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.featured-card__content {
  padding: 1rem;
}

.featured-card__title {
  font-size: 1.25rem;
  margin: 0.5rem 0;
}

.featured-card__description {
  font-size: 1rem;
  color: #555;
}

.featured-card__date {
  font-size: 0.875rem;
  color: #888;
}
@media (max-width: 768px) {
  .featured-cards {
    display: flex;
    flex-direction: column;
  }
  .featured-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;

    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
  }
}
